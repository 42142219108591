import TagManager from "react-gtm-module";
import { compact } from "./compact";
import getEnv from "./env";
import { isBrowser, isServer } from "./isBrowser";

const AUTOBIDDER = "Autobidder";
const CHANNEL = "Channel";
const ONE_BID = "OneBid";
const PRODUCT = "Product";
const SERVICE = "Service";

export const gtmInitialize = ({
  selectedPPCCampaign,
  selectedPPLCampaign,
  user,
  visiblePPCCampaigns,
  visiblePPLCampaigns,
}) => {
  const isInitialized = isBrowser()
    ? window?.dataLayer?.find((e) => e["gtm.start"])
    : false;
  if (isInitialized && user?.isLoggedIn) {
    TagManager.dataLayer({
      dataLayer: {
        ...gtmTrackingData({
          user,
          selectedPPCCampaign,
          selectedPPLCampaign,
          visiblePPCCampaigns,
          visiblePPLCampaigns,
        }),
      },
    });
    return;
  }
  const GTM_ID = getEnv().REACT_APP_GTM_ID;
  const GTM_AUTH = getEnv().REACT_APP_GTM_AUTH;
  const GTM_PREVIEW = getEnv().REACT_APP_GTM_PREVIEW;
  // for logout use case
  if (!Boolean(user) && isBrowser()) {
    TagManager.initialize({
      gtmId: GTM_ID,
      auth: GTM_AUTH,
      preview: GTM_PREVIEW,
    });
  }

  if (GTM_ID && GTM_AUTH && user?.isLoggedIn) {
    TagManager.initialize({
      gtmId: GTM_ID,
      auth: GTM_AUTH,
      preview: GTM_PREVIEW,
      dataLayer: gtmTrackingData({
        user,
        selectedPPCCampaign,
        selectedPPLCampaign,
        visiblePPCCampaigns,
        visiblePPLCampaigns,
      }),
    });
  }
};

export const clearGtmDataLayer = () => {
  const GTM = isBrowser() ? window.google_tag_manager : undefined;
  const GTM_ID = getEnv().REACT_APP_GTM_ID;

  if (GTM && GTM_ID) GTM[GTM_ID]?.dataLayer.reset();
};

const isPendoInitialized = () => {
  try {
    return isBrowser() ? Boolean(window.pendo.getAccountId()) : false;
  } catch {
    return false;
  }
};

const campaignBiddingType = (campaignSummary) => {
  if (campaignSummary) {
    return campaignSummary?.autobidder
      ? AUTOBIDDER
      : campaignSummary?.oneBid
        ? ONE_BID
        : CHANNEL;
  }
};
export const pendoInitialize = ({
  selectedPPCCampaign,
  selectedPPLCampaign,
  user,
  visiblePPCCampaigns,
  visiblePPLCampaigns,
}) => {
  if (getEnv().REACT_APP_ENV !== "production") return;

  const PENDO_API_KEY = getEnv().REACT_APP_PENDO_API_KEY;

  if (PENDO_API_KEY && user?.isLoggedIn && isBrowser()) {
    const pendoOptions = pendoTrackingData({
      user,
      selectedPPCCampaign,
      selectedPPLCampaign,
      visiblePPCCampaigns,
      visiblePPLCampaigns,
    });

    if (isPendoInitialized()) {
      window.pendo?.updateOptions?.(pendoOptions);
    } else {
      window.pendo?.initialize?.(pendoOptions);
    }
  }
};

const gtmTrackingData = ({
  user,
  selectedPPCCampaign,
  selectedPPLCampaign,
  visiblePPCCampaigns,
  visiblePPLCampaigns,
}) => ({
  visitor: compact({
    id: user.userId,
    hasListingsAccess: user.permissions?.listings?.hasAccess,
    hasPpcAccess: user.permissions?.ppc?.hasAccess,
    hasPplAccess: user.permissions?.ppl?.hasAccess,
    hasReviewsAccess: user.permissions?.reviews?.hasAccess,
    isEmployee: !!user.employeeId,
    isMultiPpc: visiblePPCCampaigns.length > 1,
    isMultiPpl: visiblePPLCampaigns.length > 1,
    isOrganizationAdmin: user.organizations.every(
      ({ isAdmin }) => isAdmin === true,
    ),
    organizationIds: user.organizations.map(({ id }) => id),
    ppcBiddingType: campaignBiddingType(user?.campaignSummary),
    ppcCampaignId: selectedPPCCampaign?.id,
    ppcCampaignIds: visiblePPCCampaigns.map(({ id }) => id),
    ppcCampaignUpgraded: selectedPPCCampaign?.isUpgraded,
    ppcCampaignType: user?.campaignSummary?.isServices ? SERVICE : PRODUCT,
    ppcStartDate: user?.campaignSummary?.creationDate,
    pplCampaignId: selectedPPLCampaign?.id,
    pplCampaignIds: visiblePPLCampaigns.map(({ id }) => id),
    userDepartments: user.profile?.departments,
    userJobLevel: user.profile?.jobLevel,
    userLanguage: user.profile?.language,
  }),
  account: compact({
    id: user.enterpriseVendorId,
    name: user?.campaignSummary?.name,
    ppcCampaignId: selectedPPCCampaign?.id,
    pplCampaignId: selectedPPLCampaign?.id,
    bizdev_first_name: user?.campaignSummary?.representative?.name,
    creationDate: user?.campaignSummary?.creationDate,
    salesChannel: user?.campaignSummary?.salesChannel,
    upgraded: user?.campaignSummary?.isUpgraded,
  }),
});

const pendoTrackingData = ({
  user,
  selectedPPCCampaign,
  selectedPPLCampaign,
  visiblePPCCampaigns,
  visiblePPLCampaigns,
}) => ({
  visitor: compact({
    id: trackingUserId(user),
    hasListingsAccess: user.permissions?.listings?.hasAccess,
    hasPpcAccess: user.permissions?.ppc?.hasAccess,
    hasPplAccess: user.permissions?.ppl?.hasAccess,
    hasReviewsAccess: user.permissions?.reviews?.hasAccess,
    isEmployee: !!user.employeeId,
    isMultiPpc: visiblePPCCampaigns.length > 1,
    isMultiPpl: visiblePPLCampaigns.length > 1,
    isOrganizationAdmin: user.organizations.every(
      ({ isAdmin }) => isAdmin === true,
    ),
    organizationIds: user.organizations.map(({ id }) => id),
    ppcBiddingType: campaignBiddingType(user?.campaignSummary),
    ppcCampaignId: selectedPPCCampaign?.id,
    ppcCampaignIds: visiblePPCCampaigns.map(({ id }) => id),
    ppcCampaignUpgraded: selectedPPCCampaign?.isUpgraded,
    ppcCampaignType: user?.campaignSummary?.isServices ? SERVICE : PRODUCT,
    ppcStartDate: user?.campaignSummary?.creationDate,
    pplCampaignId: selectedPPLCampaign?.id,
    pplCampaignIds: visiblePPLCampaigns.map(({ id }) => id),
    userDepartments: user.profile?.departments,
    userJobLevel: user.profile?.jobLevel,
    userLanguage: user.profile?.language,
  }),
  account: compact({
    id: user.enterpriseVendorId,
    name: user?.campaignSummary?.name,
    ppcCampaignId: selectedPPCCampaign?.id,
    pplCampaignId: selectedPPLCampaign?.id,
    bizdev_first_name: user?.campaignSummary?.representative?.name,
    creationDate: user?.campaignSummary?.creationDate,
    salesChannel: user?.campaignSummary?.salesChannel,
    upgraded: user?.campaignSummary?.isUpgraded,
  }),
});

const trackingUserId = (user) =>
  user.employeeId ? `internal-${user.userId}` : user.userId;

export const clearPendoDataLayer = () => {
  const PENDO = isBrowser() ? window.pendo : undefined;
  const PENDO_API_KEY = getEnv().REACT_APP_PENDO_API_KEY;

  if (
    PENDO &&
    PENDO_API_KEY &&
    typeof PENDO.flushNow === "function" &&
    isBrowser()
  ) {
    window.pendo.flushNow();
  }
};

export const gtmTracking = (eventName, context = {}) => {
  if (isServer()) return;

  TagManager.dataLayer({
    dataLayer: { event: eventName, ...context },
  });
};

export const pendoTracking = (...args) => {
  if (isServer() || !window.pendo) return () => {};
  window.pendo.track(...args);
};
